import { colorPropDef } from "@radix-ui/themes/props";
import { useSearchParams } from "@remix-run/react";
import { type ClassValue, clsx } from "clsx";
import { useEffect, useLayoutEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";
import tldjs from "tldjs";
import { pluralize } from "inflected";
import {
  VENDR_PROD_ACCOUNT,
  VENDR_PREVIEW_ACCOUNT,
  VENDR_STG_ACCOUNT,
} from "~/utils/constants";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function classNames(...arr: unknown[]) {
  return arr.filter(Boolean).join(" ");
}

export function getDomainFromURL(url: string): string | null {
  // see https://github.com/thom4parisot/tld.js/issues/117
  return tldjs.getDomain(url) || new URL(url).hostname;
}

export function getCatalogSQSAccountFromEnv(
  env: string | undefined,
): string | undefined {
  switch (env) {
    case "prod":
      return `arn:aws:kms:us-east-1:${VENDR_PROD_ACCOUNT}:key/*`;
    case "staging":
      return `arn:aws:kms:us-east-1:${VENDR_STG_ACCOUNT}:key/*`;
    case "preview":
      return `arn:aws:kms:us-east-1:${VENDR_PREVIEW_ACCOUNT}:key/*`;
    default:
      return undefined;
  }
}

export function omit<T extends Record<string, any>, K extends keyof T>(
  obj: T,
  ...key: K[]
): Omit<T, K> {
  const clone = { ...obj };
  key.forEach((k) => delete clone[k]);
  return clone;
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function head<T>(input: ArrayLike<T>): T | undefined {
  return input[0];
}

export function toCamelCase(str: string) {
  return str
    .split("_")
    .reduce((a, b, i) => {
      return a + (i > 0 ? b.charAt(0).toUpperCase() + b.slice(1) : b);
    }, "")
    .trim();
}

export function lowerCaseFirstLetter(str: string) {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export function toUpperFirst(text: string) {
  return `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;
}

export function isPlural(word: string) {
  return word === pluralize(word);
}

export function isSingular(word: string) {
  return !isPlural(word);
}

export function isObject(item: any) {
  return typeof item === "object" && !Array.isArray(item) && item !== null;
}

export function isRecord(value: unknown): value is Record<string, unknown> {
  if (typeof value !== "object" || value === null || Array.isArray(value)) {
    return false;
  }

  // Check if all keys are strings and not numeric strings or symbols
  const ownProps = Reflect.ownKeys(value as object);
  if (
    ownProps.some(
      (key) => typeof key !== "string" || !Number.isNaN(Number(key)),
    )
  ) {
    return false;
  }

  // Check if all values are defined ('unknown' includes undefined, so we need this check)
  const values = Object.values(value as object);
  if (values.some((val) => typeof val === "undefined")) {
    return false;
  }

  return true;
}

export function isIsoDate(input: string | undefined | null) {
  if (!input) {
    return false;
  }
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(input)) {
    return false;
  }
  const d = new Date(input);
  return (
    d instanceof Date && !Number.isNaN(d.getTime()) && d.toISOString() === input
  ); // valid date
}

export const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? useLayoutEffect : useEffect;

export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (delay === null) {
      return;
    }

    const id = setInterval(() => {
      savedCallback.current();
    }, delay);

    return () => {
      clearInterval(id);
    };
  }, [delay]);
}

export function hashCode(str: string): number {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    let chr = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function hashColor(str: string): typeof colorPropDef.color.default {
  return colorPropDef.color.values[
    Math.abs(hashCode(str)) % colorPropDef.color.values.length
  ];
}

export function pick<T extends object, K extends keyof T>(
  obj: T,
  keys: K[],
): Pick<T, K> {
  return keys.reduce(
    (acc, key) => {
      if (key in obj) {
        acc[key] = obj[key];
      }
      return acc;
    },
    {} as Pick<T, K>,
  );
}

export function isWorkflowId(input: string | null): boolean {
  if (input === null) {
    return false;
  }
  const pattern = /^workflow_run_[a-zA-Z0-9_-]{21}$/;
  return pattern.test(input);
}

export function isIntercomID(input: string | null): boolean {
  if (input === null || typeof input !== "string") {
    return false;
  }
  return /^[0-9]{5,6}$/.test(input);
}

export function useSearchParam(
  key: string,
): [string, (newValue: string) => void, () => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const value = searchParams.get(key) || "";

  function set(newValue: string) {
    setSearchParams((prev) => {
      prev.set(key, newValue);
      return prev;
    });
  }

  function clear() {
    setSearchParams((prev) => {
      prev.delete(key);
      return prev;
    });
  }

  return [value, set, clear];
}

/**
 * Checks if a given string is empty.
 * A string is considered empty if it is null, undefined, or contains only whitespace characters.
 *
 * @param str - The string to check.
 * @returns {boolean} - Returns true if the string is empty; otherwise, false.
 */
export function isEmptyString(str: string | null | undefined): boolean {
  // Check if the string is null, undefined, or only contains whitespace characters
  return str == null || str.trim() === "";
}

export function lineItemId(lineItem: {
  DocumentId: string;
  lineItemIndex: number;
}): string {
  return `${lineItem.DocumentId}-${lineItem.lineItemIndex}`;
}

const Capitalizations = ["Esg", "Faq"];

export function humanizeKey(key: string) {
  let humanizedKey = key
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());

  Capitalizations.forEach((capitalization) => {
    if (humanizedKey.includes(capitalization)) {
      humanizedKey = humanizedKey.replace(
        capitalization,
        capitalization.toUpperCase(),
      );
    }
  });

  return humanizedKey;
}

export function getInitials(name: string) {
  return name
    .split(" ")
    .map((n) => n[0])
    .join("")
    .toUpperCase();
}

export type Nullish<T> = T | null | undefined;

/**
 * Runs multiple queries in parallel and resolves with the first query that returns records.
 * If no query returns records, it rejects with an error.
 *
 * @param queries An array of promises representing database queries.
 * @returns A promise that resolves with the first valid result or rejects if no results are found.
 */
export async function firstQueryWithResultsWithKey<T>({
  queriesAsRecord,
  resultTest = ({ result }) => Array.isArray(result) && result.length > 0,
  noResultsReturn = null,
}: {
  queriesAsRecord: Record<string, Promise<T>>;
  resultTest?: ({ result, key }: { result: T; key: string | null }) => boolean;
  noResultsReturn?: T | null;
}): Promise<{ result: T; key: string | null }> {
  return new Promise((resolve, reject) => {
    let completed = false; // Track if a query has returned results
    let pendingCount = Object.keys(queriesAsRecord).length; // Track the number of pending queries

    if (pendingCount === 0) {
      if (noResultsReturn) {
        resolve({ result: noResultsReturn, key: null });
      } else {
        reject(new Error("No queries returned any records"));
      }
    }

    // Define a function to handle each query independently
    const handleQuery = async (query: Promise<T>, key: string) => {
      try {
        if (!completed) {
          const result = await query;

          // If the result has records and no other query has resolved
          if (resultTest({ result, key }) && !completed) {
            completed = true; // Mark as completed
            resolve({ result, key }); // Resolve with the first valid result
          }
        }
      } catch (error) {
        console.error("Query error:", error); // Log query errors
      } finally {
        // Decrement pending count and check if all queries are exhausted
        pendingCount--;
        if (pendingCount === 0 && !completed) {
          if (noResultsReturn) {
            resolve({ result: noResultsReturn, key: null });
          } else {
            reject(new Error("No queries returned any records"));
          }
        }
      }
    };

    // Start all queries in parallel
    Object.entries(queriesAsRecord).forEach(([key, query]) =>
      handleQuery(query, key),
    );
  });
}

export async function firstQueryWithResults<T>({
  queries,
  resultTest = (result) => Array.isArray(result) && result.length > 0,
  noResultsReturn = null,
}: {
  queries: Promise<T>[];
  resultTest?: (result: T) => boolean;
  noResultsReturn?: T | null;
}): Promise<T> {
  let queriesAsRecord = Object.fromEntries(
    queries.map((query, index) => [index.toString(), query]),
  );

  const { result } = await firstQueryWithResultsWithKey({
    queriesAsRecord,
    resultTest: ({ result }) => resultTest(result),
    noResultsReturn,
  });
  return result;
}

export function getElapsedTimeInMs(elapsedHRTime: [number, number]) {
  return (
    elapsedHRTime[0] * 1000 +
    parseFloat((elapsedHRTime[1] / 1_000_000).toFixed(3))
  );
}

export async function withTiming<T>(
  fn: () => Promise<T>,
): Promise<[T, number]> {
  const startHRTime = process.hrtime();
  const result = await fn();
  const elapsedHRTime = process.hrtime(startHRTime);
  const elapsedTimeInMs = getElapsedTimeInMs(elapsedHRTime);
  return [result, elapsedTimeInMs];
}

/*
Convert BigInt values to strings to avoid breaking the Remix `json` serializer
that uses JSON.stringify. The Legacy Price Range object has BigInt values that
aren't serializable.
*/
type ConvertBigIntToString<T> = T extends bigint
  ? string
  : T extends Array<infer U>
    ? Array<ConvertBigIntToString<U>>
    : T extends object
      ? { [K in keyof T]: ConvertBigIntToString<T[K]> }
      : T;

export function convertBigIntToString<T>(obj: T): ConvertBigIntToString<T> {
  if (typeof obj === "bigint") {
    return obj.toString() as ConvertBigIntToString<T>;
  }

  if (Array.isArray(obj)) {
    return obj.map(convertBigIntToString) as ConvertBigIntToString<T>;
  }

  if (typeof obj === "object" && obj !== null) {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
        key,
        convertBigIntToString(value),
      ]),
    ) as ConvertBigIntToString<T>;
  }

  return obj as ConvertBigIntToString<T>;
}

/*
Uncomment to find and log BigInt paths since they break the Remix `json` serializer
that uses JSON.stringify.

```typescript
  // Find and log BigInt paths
  const bigIntPaths = findBigIntPaths(priceCheckResult);
  if (bigIntPaths.length > 0) {
    console.log("BigInt values found at these paths:");
    bigIntPaths.forEach((path) => console.log(path));
  }

  // Log the actual BigInt values
  bigIntPaths.forEach((path) => {
    const value = path
      .split(".")
      .reduce((acc, key) => acc[key], priceCheckResult);
    console.log(`${path}: ${value.toString()}`);
  });
```
*/
// function findBigIntPaths(obj: any, path: string[] = []): string[] {
//   const bigIntPaths: string[] = [];

//   if (typeof obj === "bigint") {
//     bigIntPaths.push(path.join("."));
//   } else if (Array.isArray(obj)) {
//     obj.forEach((item, index) => {
//       bigIntPaths.push(...findBigIntPaths(item, [...path, index.toString()]));
//     });
//   } else if (typeof obj === "object" && obj !== null) {
//     Object.entries(obj).forEach(([key, value]) => {
//       bigIntPaths.push(...findBigIntPaths(value, [...path, key]));
//     });
//   }

//   return bigIntPaths;
// }

export async function rateLimit<T, U>({
  maxCallsPerInterval,
  intervalInMs,
  parallelCallCount,
  fn,
  inputData,
}: {
  maxCallsPerInterval: number;
  intervalInMs: number;
  parallelCallCount: number;
  fn: (input: T) => Promise<U>;
  inputData: T[];
}): Promise<{ result: U[] }> {
  const startTime = Date.now();
  const results: U[] = [];
  const callTimes: number[] = []; // Track timestamps of all calls
  let currentIndex = 0;

  // Process items in chunks based on parallelCallCount
  while (currentIndex < inputData.length) {
    const currentBatch: Promise<U>[] = [];

    // Calculate how many calls we can make right now
    const now = Date.now();
    //console.log("call times", callTimes);
    // Remove timestamps older than our interval window
    while (callTimes.length > 0 && callTimes[0] < now - intervalInMs) {
      callTimes.shift();
    }

    // If we've hit the rate limit, wait until we can make more calls
    if (callTimes.length >= maxCallsPerInterval) {
      //console.log("Rate limit hit, waiting for interval to reset");
      const oldestCall = callTimes[0];
      const waitTime = oldestCall + intervalInMs - now;
      await sleep(waitTime);
      //console.log("Rate limit interval reset");
      continue;
    }

    // Calculate how many new calls we can make
    const availableSlots = Math.min(
      parallelCallCount - currentBatch.length,
      maxCallsPerInterval - callTimes.length,
      inputData.length - currentIndex,
    );

    // Create the batch of calls
    for (let i = 0; i < availableSlots; i++) {
      const input = inputData[currentIndex + i];
      //console.log("Calling fn with input", input);
      const callPromise = (async () => {
        callTimes.push(Date.now());
        return fn(input);
      })();
      currentBatch.push(callPromise);
    }

    // Wait for the current batch to complete
    const batchResults = await Promise.all(currentBatch);
    results.push(...batchResults);
    currentIndex += availableSlots;
  }

  return { result: results };
}

export function enumerate<T>(array: T[]): { index: number; value: T }[] {
  return array.map((value, index) => ({ index, value }));
}

export function compact<T>(input: (T | null | undefined)[]): T[] {
  return input.filter((item) => item !== null && item !== undefined);
}

/**
 * Combine multiple header objects into one (uses append so headers are not overridden)
 */
export function combineHeaders(
  ...headers: Array<ResponseInit["headers"] | null | undefined>
) {
  const combined = new Headers();
  for (const header of headers) {
    if (!header) continue;
    for (const [key, value] of new Headers(header).entries()) {
      combined.append(key, value);
    }
  }
  return combined;
}
